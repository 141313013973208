import { createContext, useContext } from "react";
import { getTranslateFunction } from "../Language/getLanguageConstants";
import { LanguageCodes } from "../Language/types";
import { getLanguageLabelFromLocale } from "../helpers/getLanguageLabelFromLocale";

interface ContextProps {
  t: (...args: any[]) => any;
  language_code?: LanguageCodes;
  language_label?: string;
}

const LanguageContext = createContext<ContextProps>({
  t: () => null,
});

interface Props {
  children?: React.ReactNode;
  language_code?: LanguageCodes;
  language_label?: string;
}

export function LanguageContextProvider({ children, language_code }: Props) {
  const t = getTranslateFunction(language_code);

  return (
    <LanguageContext.Provider
      value={{
        t,
        language_code,
        language_label: getLanguageLabelFromLocale(t, language_code),
      }}
    >
      {children}
    </LanguageContext.Provider>
  );
}

export const useLanguageContext = () => useContext(LanguageContext);
