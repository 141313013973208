import { useState, useEffect } from "react";
import { LanguageContextProvider } from "@/base/context/LanguageContext";
import { getLocaleFromPathname } from "@/base/Language/getLocaleFromPathname";
import SimplifiedLayout from "@/components/layout/SimplifiedLayout";
import NotFoundSection from "@/components/sections/NotFound";

type NotFoundPageProps = {
  location?: Window["location"] | undefined;
};

function NotFoundPage({ location }: NotFoundPageProps) {
  const seo = { title: "Page Not Found" };

  const [pathname, setPathname] = useState("");

  const language_code = getLocaleFromPathname(pathname);

  useEffect(() => {
    if (location) {
      setPathname(location.pathname);
    }
  }, [location]);

  return (
    <LanguageContextProvider language_code={language_code}>
      <SimplifiedLayout seo={seo} type="website" language_code={language_code}>
        <NotFoundSection />
      </SimplifiedLayout>
    </LanguageContextProvider>
  );
}

export default NotFoundPage;
