import { LANGUAGE_CODE_FALLBACK } from "./getLanguageConstants";
import { LanguageCodes } from "./types";

export function getLocaleFromPathname(pathname: string): LanguageCodes {
  if (!pathname) return LANGUAGE_CODE_FALLBACK;

  const pathnameArray = pathname?.split("/");

  return (
    pathname.startsWith("/") ? pathnameArray[1] : pathnameArray[0]
  ) as LanguageCodes;
}
