import languageConstants from "./constants.json";
import { LanguageCodes } from "./types";

export const LANGUAGE_CODE_FALLBACK = "uk";

const getLanguageConstants = (language_code: LanguageCodes) =>
  languageConstants[language_code] || languageConstants[LANGUAGE_CODE_FALLBACK];

const getTranslateFunction =
  (language_code?: LanguageCodes) =>
  ([path]: [string]) => {
    const constants = getLanguageConstants(
      language_code || LANGUAGE_CODE_FALLBACK
    );

    const getFallback = () => {
      if (language_code === LANGUAGE_CODE_FALLBACK) {
        return "";
      }

      return getTranslateFunction(LANGUAGE_CODE_FALLBACK)([path]);
    };

    return path
      .split(".")
      .reduce((acc, key) => acc[key] || getFallback(), constants);
  };

export { getTranslateFunction };
