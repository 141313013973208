import clsx from "clsx";

import { useLanguageContext } from "@/base/context/LanguageContext";
import LinkComponent from "@/components/elements/LinkComponent";
import Heading from "@/components/elements/Heading";
import ImageLeft from "@/assets/images/not-found-left.webp";
import ImageRight from "@/assets/images/not-found-right.webp";

import * as styles from "./styles.module.scss";

export function NotFoundSection() {
  const { t, language_code } = useLanguageContext();

  return (
    <section className={styles.wrapper}>
      <div className={clsx("container", styles.flex)}>
        <div className={styles.decorations}>
          <img src={ImageLeft} alt="" />
          <img src={ImageRight} alt="" />
        </div>

        <div className={styles.contentWrap}>
          <p className={styles.label}>404</p>
          <Heading level={1} className={styles.heading}>
            {t`not_found.title`}
          </Heading>
          <p className={styles.text}>{t`not_found.description`}</p>

          <LinkComponent
            url={`/${language_code}`}
            type="internal"
            title={t`not_found.link`}
            variant="button_primary"
            className={styles.link}
          />
        </div>
      </div>
    </section>
  );
}
